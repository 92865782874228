import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Dialog } from '@reach/dialog';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import Image from '../components/Image';
import SEO from '../components/SEO';

export function ServicePageTemplate({
  content,
  contentComponent,
  gallery,
  title,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [openImage, setOpenImage] = useState(null);
  const ServiceContent = contentComponent || Content;

  return (
    <section className="section">
      <Dialog
        aria-label="Abrir galeria"
        isOpen={isModalOpen}
        onDismiss={() => {
          setModalOpen(false);
          setOpenImage(null);
        }}
      >
        {openImage && (
          <div className="box modal-image">
            <Image
              imageStyle={{ borderRadius: '10px 10px 0 0' }}
              imageInfo={{
                alt: openImage.caption,
                image: openImage.image,
              }}
            />
            <p className="modal-image-caption">{openImage.caption}</p>
          </div>
        )}
      </Dialog>
      <div className="container content">
        <h1 className="title has-text-white">{title}</h1>
        <ServiceContent
          className="content has-text-white service-content"
          content={content}
        />
        {gallery && gallery.length && (
          <>
            <h2 className="title has-text-white">Galeria</h2>
            <div className="gallery-grid">
              {gallery.map(galleryItem => (
                <div
                  key={galleryItem.image.id}
                  className="box gallery-grid-cell"
                  onClick={() => {
                    setOpenImage(galleryItem);
                    setModalOpen(true);
                  }}
                >
                  <Image
                    imageInfo={{
                      alt: galleryItem.caption,
                      image: galleryItem.image,
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

ServicePageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

function ServicePage({ data }) {
  const { markdownRemark: service } = data;
  const { excerpt, frontmatter } = service;
  const { featuredimage, title } = frontmatter;
  const ogImage = featuredimage.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO
        article
        description={excerpt}
        image={ogImage}
        pathname={service.fields.slug}
        title={title}
      />
      <ServicePageTemplate
        content={service.html}
        contentComponent={HTMLContent}
        {...service.frontmatter}
      />
    </Layout>
  );
}

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ServicePage;

export const pageQuery = graphql`
  query ServicePageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        featuredimage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        gallery {
          caption
          image {
            id
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;
